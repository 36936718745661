import './Footer.css'


function Foooter(){
    return (
        <div id="footer">
          <div id="footer-left">   
          <img id="footer-left-logo" src={process.env.PUBLIC_URL + "/img/logo.webp"} alt="logo Edouard Priem" width="80" height="80"/>
          </div>
          <div id="footer-center">  
          <p className="footer-center-p"> Edouard Priem</p> 
          <p className="footer-center-p"> Artiste - Musicien</p>
          <p className="footer-center-p"> edouardpriem@yahoo.fr</p>
          <p className="footer-center-p"> 06.28.93.42.00</p>
          </div>
          <div id="footer-right">  
          <p>Je vous invite à me retrouver via mes réseaux sociaux pour être au courant de mes actualités</p> 
          <div id="footer-right-img"> 
            <a href="https://www.facebook.com/edouard.priem" target="_blank" rel="noopener noreferrer" className="footer-right-link"><img className="footer-right-logo" width="70" height="70" src={process.env.PUBLIC_URL + "/img/logo-facebook.svg"} alt="logo facebook"/></a>
            <a href="https://open.spotify.com/artist/7HWIkZwNKrOtIubmPIctQI" rel="noopener noreferrer"target="_blank" className="footer-right-link" id="footer-right-link-spotify"><img className="footer-right-logo" id="footer-spotify" width="70" height="70" src={process.env.PUBLIC_URL + "/img/logo-spotify.svg"} alt="logo spotify"/></a>
            <a href="https://www.youtube.com/channel/UCK8wQkXTTOS7r76ryjjFk_Q" target="_blank" rel="noopener noreferrer" className="footer-right-link"><img className="footer-right-logo" width="70" height="70" src={process.env.PUBLIC_URL + "/img/logo-youtube.svg"} alt="logo youtube"/></a>
          </div>
          </div>
        </div>
    )
}

export default Foooter;