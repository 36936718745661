import {useState} from "react";
import './Contact.css';
import axios from "axios";
import {Button, Input, TextField} from '@mui/material';
import Swal from 'sweetalert2';


export default function Contact() {


    const styleText = {
       fColor:"white",
        mb:4
    }
    const styleButton = {
        bgcolor:"#013E50",
        width:"fit-content",
        fontSize:"1rem"
    };

    const [inputs, setInputs] = useState({
        email: "",
        name:"",
        subject: "",
        message: "",
    });
    const handleOnChange = (event) => {
        event.persist();
        setInputs((prev) => ({
        ...prev,
        [event.target.id]: event.target.value,
        }));
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();

        axios({
        method: "POST",
        url: "https://formbold.com/s/9gx83",
        data: inputs,
        })
        .then((_) => {
            Swal.fire({
                text: "Mail envoyé avec succès",
                icon: "success",
                confirmButtonText: "OK",
              });
              setInputs((prev)=>({ 
                ...prev,
                email:'', name:'', subject:'',message:''}));              
            
        })
        .catch((error) => {
            console.log("error", error);
        });
    };

    return (        
        
        <div id="contact">
            <div id="contact-container">
            <div id="contact-container-left"> 
                <div id="contact-container-left-child">
                    <p className="contact-container-left-text"> Vous avez un domaine viticole et vous souhaitez fêter la nouvelle récolte ?</p>
                    <p className="contact-container-left-text">  Un évènement familial à célébrer ?</p>
                    <p className="contact-container-left-text"> Ou juste une soirée avec vos amis ?</p>
                    <p className="contact-container-left-text" id="contact-container-left-text-italic"> Contactez-moi pour venir chanter !</p>               
                </div>                
            </div>
            <form id="contact-form" onSubmit={handleOnSubmit}>
                <h2 id="contact-title"> Formulaire de contact</h2>
                <div id="contact-form-up"> 
                <label className="contact-form-label" htmlFor="email">
                    <div className="contact-form-subtitle">
                    Votre adresse Email
                    </div>    
                        <Input
                            sx={{color:"white"}}
                            className="contact-form-input"
                            onChange={handleOnChange}
                            value={inputs.email}
                            id="email"
                            type="email"
                            name="email"
                            required
                    />
                </label>
                <label className="contact-form-label" htmlFor="name">
                    <div className="contact-form-subtitle">
                    Votre nom
                    </div>
                        <Input
                            sx={{color:"white"}}
                            className="contact-form-input"
                            onChange={handleOnChange}
                            value={inputs.name}
                            id="name"
                            type="text"
                            name="name"
                            required
                        />
                </label>
                <label className="contact-form-label" htmlFor="subject">
                    <div className="contact-form-subtitle">
                    Objet de votre demande
                    </div>
                        <Input
                            sx={{color:"white"}}
                            className="contact-form-input"
                            onChange={handleOnChange}
                            value={inputs.subject}
                            id="subject"
                            type="text"
                            name="subject"
                            required
                        />
                </label>
                </div>
                <div id="contact-form-down">
                    <label id="contact-form-down-label" htmlFor="message">
                        <div className="contact-form-subtitle contact-form-subtitle-down ">
                        Votre message
                        </div>
                    <TextField
                        multiline
                        rows={6}
                        inputProps={{ style: { color: "white" } }}
                        // sx={{input:{color:"white"}}}
                        className="contact-form-down-textarea"
                        id="message"
                        onChange={handleOnChange}
                        value={inputs.message}
                        name="message"
                        sx={styleText}
                        required
                    />
                    <Button  variant="contained"sx={styleButton} id="contact-form-submit" type="submit"> Envoyer </Button>
                    </label>
                    
                </div>
            </form>           
        </div>
        </div>
        
    );
    }