import './Navbar.css'
import {Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {NavLink} from 'react-router-dom'


function Navbar({open, setOpen}){


    const handleClick = () => {
        setOpen(!open)
    }
    const styleMobile = {
        bgcolor:"black",
        mb:"10%",
        textDecoration:"none",
        fontSize:".8rem"
    }
    const style = {
        bgcolor:"#013E50",
        width:"fit-content",
        mr:"1rem",
        textDecoration:"none"
    }
    return (
        <div id="navbar"> 
                   
            <Button name="navbar-burger" aria-label="menu-bouton" onClick={handleClick} id='navbar-burger' ><MenuIcon/></Button> 
            
           <img id="navbar-logo" src={process.env.PUBLIC_URL+"img/logo.webp"} alt="logo Edouard Priem" width="80" height="80"/>
            <h2  id="navbar-name">Edouard Priem</h2>   
            <p id="navbar-text"> Un artiste musicien près de chez vous</p>
            
            <NavLink to="/" className="navbar-navlink"><Button variant="contained" sx ={style}> Accueil </Button></NavLink>
            <NavLink to="/Bio" className="navbar-navlink"><Button variant="contained" sx ={style}> Qui suis-je ?</Button></NavLink>
            <NavLink to="/Contact" className="navbar-navlink"><Button variant="contained" sx ={style}>Contact</Button></NavLink>

            {open && 
            
            <div id="navbar-responsive"> 
            
                <Button variant="contained" onClick={handleClick} id="navbar-responsive-closebtn" sx={{ ml:"3%"}}> <CloseIcon/></Button>
            <div id="navbar-responsive-links">
                <NavLink to="/" className="navbar-responsive-link"><Button variant="contained" sx ={styleMobile} onClick={()=>{setOpen(false)}}> Accueil </Button></NavLink>
                <NavLink to="/Bio" className="navbar-responsive-link"><Button variant="contained"  sx ={styleMobile} onClick={()=>{setOpen(false)}}> Qui suis-je ?</Button></NavLink>
                <NavLink to="/Contact" className="navbar-responsive-link"><Button variant="contained"  sx ={styleMobile} onClick={()=>{setOpen(false)}}> Contact</Button></NavLink>
            </div>
            </div>   
                
            }
        </div>
    )
}

export default Navbar;