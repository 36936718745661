import './App.css';
import {useState} from 'react'
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.js';
import Footer from './components/Footer/Footer.js';
import Home from './components/Home/Home.js';
import Bio from './components/Bio/Bio.js';
import Contact from './components/Contact/Contact.js';
import PageNotFound from './components/PageNotFound/PageNotFound.js';

function App() {
  const [open, setOpen] = useState(false);
  return (
    <div className="App">      
    
      <Navbar open={open} setOpen={setOpen}/>
      
        {!open &&
      <>
      <div>
          <Routes>
            <>
              <Route path="/" element={<Home />} />
              <Route path="/Bio" element={<Bio />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="*" element={<PageNotFound />} />
            </>
          </Routes>
        </div>
        <Footer />
        </>
        }
    </div>
  );
}

export default App;
