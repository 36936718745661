import './Home.css'
import { HashLink } from 'react-router-hash-link';

function Home(){
    return (      
        <div id="home">
            <div id="home-up">
                <div id="home-up-text-left">

                <p>"Je vous propose un concert chez vous, sur votre propriété pour une occasion festive ou une soirée intimiste ou également lors d’une dégustation de vins dans votre domaine viticole."</p>
                </div>
                <div id="home-up-text-right">
                <p>Répertoire : Jacques Brel, sa vie, l’histoire de ses chansons mais aussi mes compositions personnelles.</p>
                </div>
            </div>
                <div id="home-down">
                    <div id="home-down-left">
                        <img id="home-down-img" src={process.env.PUBLIC_URL+"img/ed-sourire.webp"} alt="Edouard Priem avec une guitare" height="100" width="100" loading="lazy"/>
                    </div>
                    <div id="home-down-right">
                    <div id="home-down-right-header">
                        Bienvenue,
                    </div>
                    <div id="home-down-right-main">
                        Je m’appelle Edouard Priem, je suis chanteur et guitariste d’origine Belge, passionné de guitare depuis mon plus jeune âge. J’ai à côté de mes activités professionnelles (coaching en entreprises) toujours continué à composer des chansons. 
                    </div>
                    <div id="home-down-right-footer">            
                        <HashLink
                        className="home-navlink"
                        to="/bio#bio"
                       >
                        Pour en savoir plus
                        </HashLink>
                        <div id="home-down-right-footer-sub"> 
                        <p id="home-down-right-footer-sub-up">Vous souhaitez organiser un concert à votre domicile ou sur votre domaine ?</p>
                        <HashLink className="home-navlink" to="/Contact#contact"> Me contacter </HashLink>
                        </div>
                    </div>

                </div>

                

            </div>
        </div>
    )
}

export default Home;