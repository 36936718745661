import './Bio.css'

function Bio(){
    return (
        <div id="bio">
            <div id="bio-up">
                <h3 id="bio-up-header">
                    Trois fragments de vie
                </h3>
                <div id="bio-up-main">
                    <img id="bio-up-main-img" src={process.env.PUBLIC_URL + "/img/ed-bise.webp"} alt="Edouard Priem de face" loading="lazy" />
                    <p id="bio-up-main-text">
                    "Belge trilingue, flamand francophone comme le « Grand Jacques », je me suis très tôt senti profondément touché par les scènes de vie et les travers de la bonne vieille bourgeoisie Belge que Brel savait croquer et dévorer en chansons avec une rage et une émotion dans lesquelles je me reconnais."
                    </p>
                </div>
            </div>
            <div id="bio-middle">
                <h3 id="bio-middle-header">
                Louvain-la-Neuve (1996) : hymne des étudiants de l’UCL (Université Catholique de Louvain-la-Neuve, Belgique)
                </h3>
                <div id="bio-middle-main">
                    <div id="bio-middle-main-left">
                    <iframe  className="bio-iframe" src="https://www.youtube.com/embed/9GnpaGPt_Q0" loading="lazy" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <p>(Cliquez pour lire la vidéo)</p>
                    </div>
                    <div id="bio-middle-main-right">

                    <img id="bio-middle-main-img" src={process.env.PUBLIC_URL+ "/img/ed-jocker.webp"} loading="lazy" alt="Edouard Priem avec une guitare"/>
                    </div>
                </div>
                <p id="bio-middle-footer">La chanson phare de mon premier album est devenue l’hymne de la ville universitaire de Louvain la Neuve (Belgique) en 1996 et le demeure. </p>

            </div>
            <div id="bio-down">
                <h3 id="bio-down-header">
                L’écologie au cœur de ma dernière composition : « La Peau de l’Ours »
                </h3>
                <div id="bio-down-main">
                    <div id="bio-down-main-iframe">
                        <iframe  className="bio-iframe" src="https://www.youtube.com/embed/eJP69bV0VBs" loading="lazy" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <p>(Cliquez pour lire la vidéo)</p>
                    </div>
                    <p id="bio-down-main-text">Plus récemment « Polar Bears in Beds of Roses » (en Français « La Peau de L’Ours ») est devenue, sous l’égide de la FEE (Foundation for Environmental Education) la chanson phare d’un projet éducatif auprès des jeunes, déjà programmé dans plus de 160 écoles collèges et lycées au Portugal. Ces établissements scolaires concourent pour une compétition vidéo de la meilleure chorégraphie et/ou réécriture d’une composition musicale sur le texte de la chanson qui parle de sauver la planète de la destruction du vivant par les pollutions de tout ordre et en particulier du plastique. </p>
                </div>

            </div>


        </div>
    )
}

export default Bio;